.media-previewer > img {
  width: auto !important;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: contain;
}

.dataview-demo .p-dropdown {
  width: 14rem;
  font-weight: normal;
}

.dataview-demo {
  height: 100% !important;
}

.media_container {
  height: 100% !important;
}
.dataview-demo .product-name {
  font-size: 1.5rem;
  font-weight: 700;
}

.dataview-demo .product-description {
  margin: 0 0 1rem 0;
}

.dataview-demo .product-category-icon {
  vertical-align: middle;
  margin-right: 0.5rem;
}

.dataview-demo .product-category {
  font-weight: 600;
  vertical-align: middle;
}

.dataview-demo .product-list-item {
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 100%;
}

.dataview-demo .product-list-item img {
  width: 150px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin-right: 2rem;
}

.dataview-demo .product-list-item .product-list-detail {
  flex: 1 1 0;
}

.dataview-demo .product-list-item .p-rating {
  margin: 0 0 0.5rem 0;
}

.dataview-demo .product-list-item .product-price {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  align-self: flex-end;
}

.dataview-demo .product-list-item .product-list-action {
  display: flex;
  flex-direction: column;
}

.dataview-demo .product-list-item .p-button {
  margin-bottom: 0.5rem;
}

.dataview-demo .product-grid-item {
  margin: 0.5em;
  border: 1px solid #dee2e6;
  padding: 2rem;
}

.dataview-demo .product-grid-item .product-grid-item-top,
.dataview-demo .product-grid-item .product-grid-item-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dataview-demo .product-grid-item img {
  width: 75%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin: 2rem 0;
}

.dataview-demo .product-grid-item .product-grid-item-content {
  text-align: center;
}

.dataview-demo .product-grid-item .product-price {
  font-size: 1.5rem;
  font-weight: 600;
}

.p-grid.grid.p-nogutter.grid-nogutter {
  display: flex;
  flex-wrap: wrap;
}

.dataview-demo .product-grid-item img {
  width: 85% !important;
  height: 15vh;
}

/* Media Layout */
.media_container {
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
}

.media_container .media {
  width: 12%;
  transition: all 0.3s;
}

.layout_media {
  height: 85px;
}

.media_container .media_list {
  width: 98%;
  border-radius: 0;
  display: flex;
  align-items: center;
}

.media_container .media_grid {
  width: 14.27%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 25px 5px !important;
  margin: 0 !important;
  transition: all 0.3s;
}

.media_container .media {
  transition: all 0.3s;
}

.media_action_right,
.folder_action_right {
  display: none;
  cursor: pointer;
  top: 5px;
  width: 10px;
  right: 25px;
}

.media_action_left,
.folder_action_left {
  display: none;
  cursor: pointer;
  top: 6px;
  width: 10px;
  left: 20px;
}

.media_grid:hover .media_action_left,
.media_grid:hover .media_action_right,
.media_grid:hover .folder_action_right,
.media_grid:hover .folder_action_left,
.media_grid:hover .media_action {
  display: block !important;
}

.spinner_container {
  background-color: rgb(0, 0, 0, 0.3);
  z-index: 55;
}

.media_spinner {
  z-index: 55;
}

.menu-vertical .menu-inner * {
  box-sizing: border-box;
}

.media_list .layout_media {
  height: 50px;
  width: 57%;
}

.media:hover {
  background: rgba(198, 193, 193, 0.185);
}

.actions {
  display: none;
}

.media:hover .actions {
  display: flex;
  flex-wrap: nowrap;
}

.grid_media_checkbox {
  top: 6px;
  right: 5px;
}

.media_checkbox .p-checkbox-box {
  border-radius: 0;
  width: 18px !important;
  height: 18px !important;
}

.main_checkbox.media_checkbox .p-checkbox-box {
  border-radius: 0;
  width: 20px !important;
  height: 20px !important;
  border: 2px solid rgb(133, 146, 163) !important;
}

.media_checkbox .p-checkbox-box.p-highlight {
  border-color: rgb(133, 146, 163);
  background: transparent !important;
}

.media_checkbox .p-checkbox-box .p-checkbox-icon {
  color: rgb(133, 146, 163);
  font-size: 12px;
}

.media_type {
  text-transform: capitalize !important;
}

.media_banner {
  width: 95% !important;
}

.cards_container {
  /* max-height: calc(100vh - 500px); */
  /* max-height: calc(100vh - 24vh); */
  height: 100%;
  overflow-y: auto;
  width: 98%;
}

.folder_row:hover {
  background-color: #9f9d9d4d;
}

.selected_folder {
  background-color: #9f9d9d4d !important;
}

@media (max-width: 1024px) {
  .media_list .layout_media {
    width: 90%;
    height: 65px;
  }

  .list_media {
    padding-right: 8px !important;
  }
}

@media (max-width: 768px) {
  .media_container .media_grid {
    width: 19.5%;
  }

  .media_list .layout_media {
    width: 100%;
  }
}

@media (max-width: 540px) {
  .media_container .media_grid {
    width: 24.5%;
  }

  .layout_media {
    height: 65px;
    width: 100%;
  }

  .media_list .layout_media {
    height: 43px;
    width: 80%;
  }

  .p-button.p-component {
    padding: 10px;
  }
}

@media (max-width: 426px) {
  .media_list .layout_media {
    height: 30px;
    width: 80%;
  }
}
