.p-datatable-tbody {
  background-color: #f9f9f9;
}

.p-datatable-wrapper::-webkit-scrollbar {
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
  background-color: #f5f5f5;
  width: 7px;
  border-radius: 0  ;
}

.p-datatable-wrapper::-webkit-scrollbar-thumb {
  width: 7px;
  background-color: #cecece;
  border-radius: 10px;
}
