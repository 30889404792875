.p-dialog .p-dialog-header {
  padding: 1rem;
}

.resizable {
  position: relative;
}

/* Change cursor when hovering near edges */
.resizable:hover {
  cursor: move; /* Default to move */
}

.resizable.edge-left:hover {
  cursor: ew-resize;
}

.resizable.edge-right:hover {
  cursor: ew-resize;
}

.resizable.edge-top:hover {
  cursor: ns-resize;
}

.resizable.edge-bottom:hover {
  cursor: ns-resize;
}
.resize-handle {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: purple;
  border: 1px solid white;
  cursor: pointer;
}

#outLine > div {
  outline: 1px solid #339ecccc !important ;
}

#mute_screens {
  color: #58697c !important;
}
