:root {
    --primary: #6366f1;

}

.days_label {
    top: auto !important;
    margin-top: 0 !important;
}

.days_checkbox .p-checkbox-box {
    padding: 22px !important;
    border-radius: 0 !important;
}

@media(max-width:769px) {
    .p-button-label {
        font-size: 13px !important;
    }

    .p-selectbutton .p-button.p-component {
        padding: 8px !important;
    }
}

@media(max-width:375px) {

    .playlist_card .p-card-body {
        padding: 12px !important;
    }
}

.days_list ul {
    padding: 0;
    margin: 0;
    clear: both;
}

.days_list ul li {
    list-style-type: none;
    list-style-position: outside;
    float: left;
    width: calc(100% / 7);
}

.days_list ul li:first-of-type label {
    -webkit-border-top-left-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-bottomleft: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.days_list ul li:last-of-type label {
    -webkit-border-top-right-radius: 5px;
    -webkit-border-bottom-right-radius: 5px;
    -moz-border-radius-topright: 5px;
    -moz-border-radius-bottomright: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.days_list ul li input[type="checkbox"]:not(:checked),
.days_list ul li input[type="checkbox"]:checked {
    position: absolute;
    left: -9999%;
}

.days_list ul li input[type="checkbox"]+label {
    width: 100%;
    display: inline-block;
    padding: 10px;
    cursor: pointer;
    border: 1px solid var(--primary);
    color: var(--primary);
    background-color: white;
    margin-bottom: 10px;
    transition: all .3s;
    text-align: center;
}

.days_list ul li input[type="checkbox"]:checked+label {
    border: 1px solid var(--primary);
    color: white;
    background-color: var(--primary);
}

.select_btn .p-selectbutton .p-button {
    padding: 0.5rem 1.25rem !important;
}

.active_category {
    background-color: #e7e7ff;
    color: #696cff;
    font-weight: bold;
}

.scroll_container{
    overflow-y: auto;
    max-height: 400px;
}

.scroll_container.scroll_div::-webkit-scrollbar {
    width: 8px;
    background-color: #090909;
    border-radius: 10px;
}
.scroll_div::-webkit-scrollbar-thumb {
    background-color: #878788;
    border: 1px solid #F5F5F5;
    border-radius: 10px;
}

.scroll_div::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #F5F5F5;
    background-color: #F5F5F5;
    border-radius: 10px;
}

.device_assign_container{
    overflow-x: hidden;

}